import React, { useState, useEffect } from "react";
import "./App.css";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { FaLightbulb, FaFan, FaWater, FaUmbrella, FaBeer} from "react-icons/fa";


const App = () => {
  const [currentTime, setCurrentTime] = useState(new Date());
  const [bombaValue, setBombaValue] = useState(50);
  const [cascadaValue, setCascadaValue] = useState(50);
  const [switches, setSwitches] = useState({
    LED: false,
    LUCES: false,
    VENT: false,
    CLORO: true,
    TOLDO: false,
    BEER: true,
  });

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);
    return () => clearInterval(timer);
  }, []);

  const handleSwitchToggle = (name) => {
    setSwitches((prev) => ({ ...prev, [name]: !prev[name] }));
  };

  return (
    <div className="app">
      <header className="header">
        <h1>SIEMENS</h1>
        <h2>PLC-FRAN</h2>
        <p>{currentTime.toLocaleString()}</p>
      </header>
      <div className="content">
        <div className="section">
          <h3>BOMBA</h3>
          <div className="modern-gauge">
            <CircularProgressbar
              value={bombaValue}
              text={`${bombaValue}%`}
              styles={buildStyles({
                textColor: "#007ac2",
                pathColor: "#007ac2",
                trailColor: "#d6d6d6",
              })}
            />
            <input
              type="range"
              min="0"
              max="100"
              value={bombaValue}
              onChange={(e) => setBombaValue(e.target.value)}
            />
          </div>
        </div>
        <div className="section">
          <h3>CASCADA</h3>
          <div className="modern-gauge">
            <CircularProgressbar
              value={cascadaValue}
              text={`${cascadaValue}%`}
              styles={buildStyles({
                textColor: "#007ac2",
                pathColor: "#4caf50",
                trailColor: "#d6d6d6",
              })}
            />
            <input
              type="range"
              min="0"
              max="100"
              value={cascadaValue}
              onChange={(e) => setCascadaValue(e.target.value)}
            />
          </div>
        </div>
        <div className="switches">
          {Object.entries(switches).map(([key, value]) => (
            <div key={key} className="switch">
              <label>
                {key === "LED" && <FaLightbulb />}
                {key === "LUCES" && <FaLightbulb />}
                {key === "VENT" && <FaFan />}
                {key === "CLORO" && <FaWater />}
                {key === "TOLDO" && <FaUmbrella />}
                {key === "BEER" && <FaBeer />}
                {` ${key}`}
              </label>
              <button
                className={value ? "on" : "off"}
                onClick={() => handleSwitchToggle(key)}
              >
                {value ? "ON" : "OFF"}
              </button>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default App;

